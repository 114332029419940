$color_black: #000000;
$color_white: #ffffff;
$color_blue: #0077FF;
$color_gray: #F5F5F5;

$color_primary: $color_blue;
$color_primary_hover: lighten($color_primary, 10%);
$color_primary_active: darken($color_primary, 10%);

// $color_border: $color_primary;

$color_selection_text: white;
$color_selection_bg: $color_primary;

$color_placeholder: $color_gray;

$color_bg: #ffffff;

$header_height: 72px;
$header_height_mob: 56px;

$container_width: 1392px;

$desk: 960px;
$tablet: 820px;
$tablet_min: 821px;
$mobile: 495px;
$mobile_min: 496px;

$font: 'Inter', 'Helvetica', sans-serif; 
$fontAlt: 'Mulish', 'Helvetica', sans-serif; 

@mixin scrollWithoutScroll() {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
}
@mixin scrollWithoutScrollHorizontal() {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-height: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
        background: transparent;
    }
}
@mixin transition() {
    transition: 0.2s;
    &:hover {
        transition: 0.05s;
    }
    &:hover {
        transition: 0.02s;
    }
}

@mixin sideFaders($width: 12px, $color: $color_white, $inset: 0%) {
    &:before,
    &:after {
        content: "";
        display: block;
        top: 0;
        bottom: 0;
        width: $width;
        position: absolute;
        pointer-events: none;
        z-index: 2;
    }
    &:before {
        left: 0;
        background: linear-gradient(to right, $color $inset, transparentize($color, 1));
    }
    &:after {
        right: 0;
        background: linear-gradient(to left, $color $inset, transparentize($color, 1));
    }
}