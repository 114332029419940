
.screen {
    opacity: 0.5;
    transition: .2s;
    &.__loaded {
        opacity: 1;
    }
    .screen__image {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        video,
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}