@import "../../css/_vars.scss";

.screenPreview {
    width: 100%;
    display: flex;
    justify-content: center;
    perspective: 600px;
    transform-style: preserve-3d;
    perspective-origin: center center;
    .screenPreview__list {
        position: relative;
        aspect-ratio: 1/1;
        height: 100%;
        transform: translateX(17%) rotateX(-20deg) rotateY(45deg);
        transform-style: preserve-3d;
        transition: .4s;
        transform-origin: center center;
    }
    .screenPreview__item {
        background-color: $color_white;
        border: 1px solid $color_blue;
        position: absolute;
        width: 50%;
        height: 50%;
        display: flex;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        transition: .4s;
        video,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &.__left {
            // transform-origin: right;
            transform: translateX(-50%);
            ._number {
                // top: 10px;
                // left: 10px;
            }
        }
        &.__right {
            transform: rotateY(-90deg) translateX(50%);
            ._number {
                // top: 10px;
                // right: 10px;
            }
        }
        &.__bottom {
            transform: translateY(50%) rotateX(90deg) translate(-50%, 50%) ;
            // transform-origin: bottom;
            // transform:rotateX(-90deg) translate3d(-50%, -50%, 50%);
            ._number {
                // bottom: 10px;
                // left: 10px;
            }
        }
        ._number {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $color_blue;
            color: $color_white;
            font-weight: bold;
            font-size: 24px;
            display: flex;
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            margin: auto;
            align-items: center;
            justify-content: center;
            
        }
    }
}

.screenList {
    display: flex;
    flex-direction: column;
    padding: 24px;
    .screenList__item {
        display: flex;
        flex-direction: column;
        &:not(:first-child){
            margin-top: 48px;
        }
        ._title {
            margin-bottom: 24px;
        }
        ._list {
            display: flex;
            flex-wrap: wrap;
            margin: -4px;
            &>*{
                margin: 4px;
                width: calc(100% / 3 - 8px);
            }
        }
        ._item {
            border-radius: 4px;
            display: flex;
            aspect-ratio: 1.5/1;
            background-color: $color_gray;
            border: 2px solid transparent;
            
            cursor: pointer;
            @include transition();
            &:hover {
                box-shadow: 0 0 10px $color_blue;
            }
            &.__active {
                border: 2px solid $color_blue;
            }
            video,
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}