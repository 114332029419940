@import "../../css/_vars.scss";

.main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
}
.main_preview {
    flex-basis: 30%;
    min-height: 30vh;
    padding: 16px;
    display: flex;
}
.main_remote {
    flex-grow: 1;
    @include scrollWithoutScroll();
}
